import moment from 'moment';
import fileDownload from 'js-file-download';
import getReportTitle from '../helpers/getReportTitle';
import { API_NAMES } from '../helpers/api-names';
import { DEFAULT_APP_SETTINGS } from '../helpers/constants';
import actionTypes from './actionTypes';
import { addErrorMessage } from './messages';
import { filterOutInactiveTrailersIfNeeded } from '../helpers/functions';
import { getStatus, reconciliateTrailersWithPerformance, getBPV75Value } from '../reducers/datacleaners';
import { worker } from '../app/web-workers/aws-web-worker';
import {
  selectDisplayTrailers,
  selectDisplayTrailersEBPMS,
  selectMaxErrorMargin,
  selectPerformanceKeys,
  selectWindowSize
} from '../selectors';
import { isPerformanceMapReady, selectPerformanceMap } from '../selectors/ebpms-trailers-performance';
import { selectAllItems } from '../selectors/ebpms-trailers';
import { getTrailersNewVersion } from '../app/feature-flags/get-trailers-new-version';
import { Logger } from '../app/helpers/logger';
import { calcNextPage } from '../actions/thunks/fetch-trailers';

function getAllTrailersRequest() {
  return {
    type: actionTypes.EBPMS_GET_TRAILERS_REQUEST
  };
}

// wait a total of maxRetries x timeBetweenRetries = 30 * 100 or 3000 milliseconds.
const performanceMapFinished = getState => {
  const maxRetries = 100;
  const timeBetweenRetries = 500;
  let currentAttempt = 1;

  return new Promise((resolve, reject) => {
    const verify = () => {
      if (isPerformanceMapReady(getState())) {
        resolve();
      } else if (currentAttempt >= maxRetries) {
        reject(new Error('Performance response timed out'));
      } else {
        currentAttempt += 1;
        setTimeout(verify, timeBetweenRetries);
      }
    };
    verify();
  });
};

const getAllTrailersSuccessUpdateStore = ({
  newAllItems,
  trailersFilteredBySettings,
  performanceKey,
  fallbackPerformanceKey,
  final
}) => {
  return {
    type: actionTypes.EBPMS_GET_TRAILERS_SUCCESS,
    allItems: newAllItems,
    items: trailersFilteredBySettings,
    key: performanceKey,
    fallbackPerformanceKey,
    final
  };
};

const getAllTrailersSuccess = (incomingTrailers, final) => {
  return async (dispatch, getState) => {
    if (final) {
      getPerformanceEBPMSWithPagination(dispatch, getState);
      try {
        await performanceMapFinished(getState);
      } catch (err) {
        Logger.error(err);
        return;
      }
    }

    const state = getState();
    const allItems = selectAllItems(state);
    const performanceMap = selectPerformanceMap(state);
    const displayTrailers = selectDisplayTrailers(state);
    const displayTrailersEBPMS = selectDisplayTrailersEBPMS(state);
    const { performanceKey, fallbackPerformanceKey } = selectPerformanceKeys(state);
    const mergedTrailers = (allItems || []).concat(incomingTrailers);
    const newAllItems = reconciliateTrailersWithPerformance(
      mergedTrailers,
      performanceMap,
      performanceKey,
      fallbackPerformanceKey
    );

    const trailersFilteredBySettings = filterOutInactiveTrailersIfNeeded({
      trailers: newAllItems,
      displayTrailers,
      displayTrailersEBPMS,
      isEBPMS: true
    });

    dispatch(
      getAllTrailersSuccessUpdateStore({
        newAllItems,
        trailersFilteredBySettings,
        performanceKey,
        fallbackPerformanceKey,
        final
      })
    );
  };
};

export const filterTrailers = () => (dispatch, getState) => {
  const state = getState();
  const {
    ebpmsTrailers: { allItems }
  } = state;
  const displayTrailers = selectDisplayTrailers(state);
  const displayTrailersEBPMS = selectDisplayTrailersEBPMS(state);

  const trailersFilteredBySettings = filterOutInactiveTrailersIfNeeded({
    trailers: allItems,
    displayTrailers,
    displayTrailersEBPMS,
    isEBPMS: true
  });

  dispatch({
    type: actionTypes.EBPMS_FILTER_TRAILERS,
    items: trailersFilteredBySettings
  });
};

function getAllTrailersFailure(error) {
  return {
    type: actionTypes.EBPMS_GET_TRAILERS_FAILURE,
    error
  };
}

function getEBPMSTrailersWithPagination(dispatch, getState, nextPage = 1) {
  const url = 'v2/trailers?view=TrailerFitView';
  const batchLimit = '800';
  worker
    .Api({
      method: 'get',
      api: API_NAMES.EBPMS,
      endpoint: url,
      headers: { limit: batchLimit, page: nextPage.toString() }
    })
    .then(res => {
      let final = false;
      const localNextPage = calcNextPage(batchLimit, res?.metadata?.fullCount, nextPage);

      if (localNextPage) {
        getEBPMSTrailersWithPagination(dispatch, getState, localNextPage);
      } else {
        final = true;
      }
      dispatch(getAllTrailersSuccess(res.assets, final));
    })
    .catch(err => dispatch(getAllTrailersFailure(err)));
}
function getEBPMSTrailersWithPaginationOld(dispatch, getState, nextPage) {
  let url = 'v2/trailers';
  if (nextPage) {
    url += `?pageKey=${nextPage}`;
  }

  worker
    .Api({ method: 'get', api: API_NAMES.EBPMS, endpoint: url })
    .then(res => {
      let final = false;
      if (res.metadata?.nextPage) {
        getEBPMSTrailersWithPagination(dispatch, getState, res.metadata?.nextPage);
      } else {
        final = true;
      }
      dispatch(getAllTrailersSuccess(res.assets, final));
    })
    .catch(err => dispatch(getAllTrailersFailure(err)));
}

function getEBPMSTrailerIDList(dispatch) {
  worker
    .Api({ method: 'get', api: API_NAMES.EBPMS, endpoint: 'trailers/ids' })
    .then(res =>
      dispatch({
        type: actionTypes.GET_EBPMS_TRAILERS_ID_LIST_SUCCESS,
        idList: res
      })
    )
    .catch(err => dispatch({ type: actionTypes.GET_EBPMS_TRAILERS_ID_LIST_FAILURE, error: err }));
}

function getPerformanceEBPMSWithPagination(dispatch, getState, nextPage) {
  let url = 'v2/trailers/performances';
  if (nextPage) {
    url += `?pageKey=${nextPage}`;
  }
  worker
    .Api({ method: 'get', api: API_NAMES.EBPMS, endpoint: url })
    .then(res => {
      let final = false;
      if (res.metadata?.nextPage) {
        getPerformanceEBPMSWithPagination(dispatch, getState, res.metadata.nextPage);
      } else {
        final = true;
      }

      const updateAction = {
        type: actionTypes.GET_EBPMS_TRAILERS_PERFORMANCE_SUCCESS,
        list: res.assets,
        final: final
      };
      dispatch(updateAction);
      dispatch(updateEBPMSTrailersPerformance);
    })
    .catch(err => dispatch({ type: actionTypes.GET_EBPMS_TRAILERS_PERFORMANCE_FAILURE, error: err }));
}

export const updateEBPMSTrailersPerformance = () => (dispatch, getState) => {
  const state = getState();
  const displayTrailers = selectDisplayTrailers(state);
  const displayTrailersEBPMS = selectDisplayTrailersEBPMS(state);

  const {
    ebpmsTrailers: { allItems, processing, firstTrailersReceived },
    ebpmsTrailerPerformance: { performanceMap }
  } = state;

  if (allItems == null || !firstTrailersReceived) {
    return;
  }

  const { performanceKey, fallbackPerformanceKey } = selectPerformanceKeys(state);
  const newAllItems = reconciliateTrailersWithPerformance(
    allItems,
    performanceMap,
    performanceKey,
    fallbackPerformanceKey
  );
  const trailersFilteredBySettings = filterOutInactiveTrailersIfNeeded({
    trailers: newAllItems,
    displayTrailers,
    displayTrailersEBPMS,
    isEBPMS: true
  });

  dispatch(
    getAllTrailersSuccessUpdateStore({
      newAllItems,
      trailersFilteredBySettings,
      performanceKey,
      fallbackPerformanceKey,
      final: !processing
    })
  );
};

function getAllEBPMSTrailers() {
  return (dispatch, getState) => {
    const { items: trailers, processing } = getState().ebpmsTrailers;
    if ((!trailers || trailers.length === 0) && !processing) {
      dispatch(getAllTrailersRequest());
      getEBPMSTrailerIDList(dispatch);
      const useGetTrailersNewVersion = getTrailersNewVersion(getState());
      if (useGetTrailersNewVersion) {
        getEBPMSTrailersWithPagination(dispatch, getState);
      } else {
        getEBPMSTrailersWithPaginationOld(dispatch, getState);
      }
    }
  };
}
function getPastTrailersRequest() {
  return {
    type: actionTypes.EBPMS_GET_PAST_TRAILERS_REQUEST
  };
}

function getPastETrailersSuccess(trailers) {
  return {
    type: actionTypes.EBPMS_GET_PAST_TRAILERS_SUCCESS,
    trailers
  };
}

function getPastTrailersFailure(error) {
  return {
    type: actionTypes.EBPMS_GET_PAST_TRAILERS_FAILURE,
    error
  };
}

function getPastEBPMSTrailers(date) {
  return (dispatch, getState) => {
    const state = getState();
    const windowSize = selectWindowSize(state);
    const maxErrorMargin = selectMaxErrorMargin(state);
    dispatch(getPastTrailersRequest());
    worker
      .Api({
        method: 'get',
        api: API_NAMES.EBPMS,
        endpoint: `performances?windowSize=${windowSize}&endTime=${moment(date)
          .endOf('day')
          .valueOf()}&errorMargin=${maxErrorMargin}`
      })
      .then(data => {
        const pastTrailers = data.map(trailer => {
          return {
            ...trailer,
            deviceId: trailer.assetId,
            datetime: trailer.end_datetime,
            status: getStatus(
              trailer.performance,
              getBPV75Value(trailer.performances?.['7_5']?.performance, trailer.performance),
              trailer?.metadata?.odr_trailer_type
            ),
            performance: trailer.performance,
            loadAlgorithm: {
              status: getStatus(
                trailer.performances?.load_6_5?.performance,
                getBPV75Value(trailer.performances?.load_7_5?.performance, trailer.performances?.load_6_5?.performance),
                trailer?.metadata?.odr_trailer_type
              ),
              datetime: trailer.end_datetime,
              performance: trailer.performances?.load_6_5?.performance
            }
          };
        });
        return dispatch(getPastETrailersSuccess(pastTrailers));
      })
      .catch(err => dispatch(getPastTrailersFailure(err)));
  };
}

function getTrailerDetailRequest(deviceId) {
  return {
    type: actionTypes.GET_TRAILER_DETAIL_REQUEST,
    deviceId
  };
}

function getTrailerDetailSuccess(deviceId, trailer) {
  return {
    type: actionTypes.GET_TRAILER_DETAIL_SUCCESS,
    deviceId,
    trailer
  };
}

function getTrailerDetailFailure(deviceId, error) {
  return {
    type: actionTypes.GET_TRAILER_DETAIL_FAILURE,
    deviceId,
    error
  };
}

function getTrailerDetail(deviceId) {
  return dispatch => {
    dispatch(getTrailerDetailRequest(deviceId));
    worker
      .Api({ method: 'get', api: API_NAMES.EBPMS, endpoint: `trailers/${deviceId}` })
      .then(res => {
        if (!res || !res.device_id || res.device_id !== deviceId) {
          throw new Error('wrong behaviour from API');
        }
        const { performanceHistory } = res;
        if (!performanceHistory || !Array.isArray(performanceHistory) || performanceHistory.length === 0) {
          // dispatch(addErrorMessage(`Sorry, trailer ${deviceId} has no performance history.`))
          throw new Error(`${deviceId} has no performance history.`);
        }
        return dispatch(getTrailerDetailSuccess(deviceId, res));
      })
      .catch(err => dispatch(getTrailerDetailFailure(deviceId, err)));
  };
}

function selectEBPMSTrailer(deviceId) {
  return dispatch => {
    dispatch({
      type: actionTypes.SELECT_TRAILER,
      deviceId
    });
    if (deviceId) {
      dispatch(getTrailerDetail(deviceId));
    }
  };
}

function getReportRequest(filename) {
  return {
    type: actionTypes.GET_REPORT_REQUEST,
    filename
  };
}

function getReportSuccess(filename) {
  return {
    type: actionTypes.GET_REPORT_SUCCESS,
    filename
  };
}

function getReportFailure(filename, err) {
  console.error(err);
  return {
    type: actionTypes.GET_REPORT_FAILURE,
    filename
  };
}

function getEBPMSReport(trailer, options) {
  return (dispatch, getState) => {
    const state = getState();
    const settingsWidowSize = selectWindowSize(state);
    const maxErrorMargin = selectMaxErrorMargin(state);
    const { deviceId, tipNum } = trailer;
    const windowSize = options.windowSize ?? settingsWidowSize;
    const { date, errorMargin = maxErrorMargin } = options;
    const params = {
      queryStringParameters: {
        windowSize: windowSize || DEFAULT_APP_SETTINGS.windowSize,
        errorMargin: errorMargin || DEFAULT_APP_SETTINGS.maxErrorMargin,
        endTime: +date.endOf('day')
      },
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'arraybuffer'
    };
    if (tipNum && date) {
      const filename = getReportTitle(tipNum, date, windowSize);
      dispatch(getReportRequest(filename));
      worker
        .Api({ method: 'get', api: API_NAMES.EBPMS, endpoint: `report/${deviceId}`, ...params })
        .then(res => {
          fileDownload(res, filename);
          dispatch(getReportSuccess(filename));
        })
        .catch(err => {
          dispatch(addErrorMessage(`Sorry, could not download ${filename}.`));
          dispatch(getReportFailure(filename, err));
        });
    }
  };
}

function cleanReports(reports) {
  return reports.map(report => {
    return {
      ...report,
      trailer: {
        tipNum: report.internal_fleet_id,
        customerFleetNum: report.customer_number,
        deviceId: report.device_id
      },
      reportdate: moment(report.report_time),
      user: report.created_by,
      creationdate: moment(report.createTime),
      windowSize: report.window_size,
      report: report.file_name
    };
  });
}

function requestReports() {
  return dispatch => {
    dispatch(getReportsRequest());
    callReportsAPI(dispatch);
  };
}

function callReportsAPI(dispatch, lastEvaluatedKey) {
  const body = {
    LastEvaluatedKey: lastEvaluatedKey || null
  };
  worker
    .Api({ method: 'post', api: API_NAMES.EBPMS, endpoint: 'report/historic', body })
    .then(res => {
      if (res.LastEvaluatedKey) {
        callReportsAPI(dispatch, res.LastEvaluatedKey);
      }
      const cleanedReports = cleanReports(res.Items);
      return dispatch(getReportsSuccess(cleanedReports, !res.LastEvaluatedKey));
    })
    .catch(err => dispatch(getReportsFailure(err)));
}

function getReportsRequest() {
  return {
    type: actionTypes.GET_REPORTS_REQUEST
  };
}

function getReportsSuccess(reports, final) {
  return {
    type: actionTypes.GET_REPORTS_SUCCESS,
    reports,
    final
  };
}

function getReportsFailure(error) {
  return {
    type: actionTypes.GET_REPORTS_FAILURE,
    error
  };
}

function changeTrailerCommentRequest(comment, time, deviceId) {
  return {
    type: actionTypes.CHANGE_TRAILER_COMMENT_REQUEST,
    comment: comment,
    time,
    deviceId
  };
}

function changeTrailerCommentSuccess() {
  return {
    type: actionTypes.CHANGE_TRAILER_COMMENT_SUCCESS
  };
}

function changeTrailerCommentFailure(lastComment, lastTime, deviceId, error) {
  return {
    type: actionTypes.CHANGE_TRAILER_COMMENT_FAILURE,
    lastComment: lastComment,
    lastTime,
    deviceId,
    error
  };
}

function updateTrailerPerformanceAlgorithmRequest(algorithm, time, deviceId) {
  return {
    type: actionTypes.UPDATE_TRAILER_PERFORMANCE_ALGORITHM_REQUEST,
    algorithm,
    time,
    deviceId
  };
}

function updateTrailerPerformanceAlgorithmSuccess() {
  return {
    type: actionTypes.UPDATE_TRAILER_PERFORMANCE_ALGORITHM_SUCCESS
  };
}

function updateTrailerPerformanceAlgorithmFailure(lastAlgorithm, lastTime, deviceId, error) {
  return {
    type: actionTypes.UPDATE_TRAILER_PERFORMANCE_ALGORITHM_FAILURE,
    lastAlgorithm,
    lastTime,
    deviceId,
    error
  };
}

function changeTrailerComment(comment, lastComment, lastTime, deviceId) {
  return dispatch => {
    dispatch(changeTrailerCommentRequest(comment, Date.now(), deviceId));
    const body = comment;
    worker
      .Api({ method: 'post', api: API_NAMES.EBPMS, endpoint: `followUpComment/${deviceId}`, body })
      .then(() => {
        return dispatch(changeTrailerCommentSuccess());
      })
      .catch(err => dispatch(changeTrailerCommentFailure(lastComment, lastTime, deviceId, err)));
  };
}

function updatePerformanceAlgorithmVersion(algorithm, lastAlgorithm, lastTime, deviceId) {
  return dispatch => {
    dispatch(updateTrailerPerformanceAlgorithmRequest(algorithm, Date.now(), deviceId));
    const body = algorithm;
    worker
      .Api({ method: 'post', api: API_NAMES.EBPMS, endpoint: `performanceAlgorithm/${deviceId}`, body })
      .then(() => {
        return dispatch(updateTrailerPerformanceAlgorithmSuccess());
      })
      .catch(err => dispatch(updateTrailerPerformanceAlgorithmFailure(lastAlgorithm, lastTime, deviceId, err)));
  };
}

function changeTrailerLastIntervention(newData, deviceId) {
  return {
    type: actionTypes.CHANGE_TRAILER_LAST_INTERVENTION_SUCCESS,
    deviceId,
    lastIntervention: newData
  };
}

export default {
  getAllEBPMSTrailers,
  selectEBPMSTrailer,
  getEBPMSReport,
  requestReports,
  getPastEBPMSTrailers,
  changeTrailerComment,
  updatePerformanceAlgorithmVersion,
  changeTrailerLastIntervention,
  filterTrailers
};

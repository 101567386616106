import { defineMessages } from 'react-intl';

// Short phrase or word likely to be used more than once in the application
export const short = defineMessages({
  sections: {
    id: 'short.sections',
    defaultMessage: 'Sections',
    description: ''
  },
  toggle: {
    id: 'short.toggle',
    defaultMessage: 'Toggle',
    description: ''
  },
  follow: {
    id: 'short.follow',
    defaultMessage: 'Follow',
    description: ''
  },
  passMeaning: {
    id: 'short.passMeaning',
    defaultMessage: 'The last daily Braking Performance Value of the trailer is above or equal to 45%',
    description: 'Status pass meaning'
  },
  passOldMeaning: {
    id: 'short.passOldMeaning',
    defaultMessage:
      'The braking event data does not enable a valid Braking Performance Value to be produced today but last valid result was above or equal to 45%',
    description: 'ols pass status meaning'
  },
  failMeaning: {
    id: 'short.failMeaning',
    defaultMessage: 'Last Braking Performance Value of the trailer is below 45%',
    description: 'Status fail meaning'
  },
  failOldMeaning: {
    id: 'short.failOldMeaning',
    defaultMessage:
      'The braking event data does not enable a valid Braking Performance Value to be produced today but last valid result was below 45%',
    description: 'status old fail meaning'
  },
  actionRequiredOldMeaning: {
    id: 'short.actionRequiredOldMeaning',
    defaultMessage:
      'The braking event data does not enable a valid Braking Performance Value to be produced today but last valid result was below 45% at 7.5B coupling head pressure',
    description: 'status old fail meaning'
  },
  unknownMeaning: {
    id: 'short.unknownMeaning',
    defaultMessage:
      'The braking event data does not enable a valid Braking Performance Value at the moment; more braking events are required',
    description: 'Status unknown meaning'
  },
  cancel: {
    id: 'short.cancel',
    defaultMessage: 'Cancel',
    description: ''
  },
  customer: {
    id: 'short.customer',
    defaultMessage: 'Customer',
    description: ''
  },
  legend: {
    id: 'short.legend',
    defaultMessage: 'Legend',
    description: ''
  },
  tipRef: {
    id: 'short.tipRef',
    defaultMessage: 'TIP Ref',
    description: 'TIP is a name do not translate'
  },
  fleetNo: {
    id: 'short.fleetNo',
    defaultMessage: 'Fleet No.',
    description: ''
  },
  brakePerformanceValue: {
    id: 'short.brakePerformanceValue',
    defaultMessage: 'Braking Performance Value',
    description: ''
  },
  brakePerformanceValue7_5: {
    id: 'short.brakePerformanceValue7_5',
    defaultMessage: 'BPV 7.5B',
    description: ''
  },
  brakePerformanceValueStandard: {
    id: 'short.brakePerformanceValueStandard',
    defaultMessage: 'BPV Standard Algorithm',
    description: ''
  },
  brakePerformanceValueLoad: {
    id: 'short.brakePerformanceValueLoad',
    defaultMessage: 'BPV Load Algorithm',
    description: ''
  },
  lastTest: {
    id: 'short.lastTest',
    defaultMessage: 'Last Test',
    description: ''
  },
  report: {
    id: 'short.report',
    defaultMessage: 'report',
    description: ''
  },
  ebsMake: {
    id: 'short.ebsMake',
    defaultMessage: 'EBS Make',
    description: 'EBS Electronic Braking System.'
  },
  ebsVersion: {
    id: 'short.ebsVersion',
    defaultMessage: 'EBS version',
    description: 'EBS Electronic Braking System.'
  },
  absVersion: {
    id: 'short.absVersion',
    defaultMessage: 'ABS version',
    description: 'ABS anti-lock Braking System.'
  },
  yom: {
    id: 'short.yom',
    defaultMessage: 'YOM',
    description: 'YOM is a name do not translate'
  },
  chassisNo: {
    id: 'short.chassisNo',
    defaultMessage: 'Chassis No.',
    description: ''
  },
  fail: {
    id: 'short.fail',
    defaultMessage: 'Fail',
    description: ''
  },
  pass: {
    id: 'short.pass',
    defaultMessage: 'Pass',
    description: ''
  },
  all: {
    id: 'short.all',
    defaultMessage: 'All',
    description: ''
  },
  recommended: {
    id: 'short.recommended',
    defaultMessage: 'Recommended',
    description: ''
  },
  trailerPerformance: {
    id: 'short.trailerPerformance',
    defaultMessage: 'Trailer Performance',
    description: ''
  },
  absCount: {
    id: 'short.absCount',
    defaultMessage: 'ABS count',
    description: 'ABS - Anti-lock Braking System'
  },
  absSystem: {
    id: 'short.absSystem',
    defaultMessage: 'ABS system',
    description: 'ABS - Anti-lock Braking System'
  },
  activeTrailers: {
    id: 'short.activeTrailers',
    defaultMessage: 'Active trailers',
    description: ''
  },
  airSuspension: {
    id: 'short.airSuspension',
    defaultMessage: 'Air suspension',
    description: ''
  },
  allDtcEvents: {
    id: 'short.allDtcEvents',
    defaultMessage: 'All DTC events',
    description: 'DTC is a name do not translate'
  },
  allRecordedEvents: {
    id: 'short.allRecordedEvents',
    defaultMessage: 'All recorded events',
    description: ''
  },
  avgAxleLoad: {
    id: 'short.avgAxleLoad',
    defaultMessage: 'Avg axle load',
    description: 'Avg is the abbreviation of the word average'
  },
  axleDetails: {
    id: 'short.axleDetails',
    defaultMessage: 'Axle details',
    description: ''
  },
  axleLoad: {
    id: 'short.axleLoad',
    defaultMessage: 'Axle load',
    description: ''
  },
  hyphens: {
    id: 'short.hyphens',
    defaultMessage: '- - -',
    description: ''
  },
  axleNum: {
    id: 'short.axleNum',
    defaultMessage: 'Axle no. {value}',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  axles: {
    id: 'short.axles',
    defaultMessage: 'Axles',
    description: ''
  },
  axlesModel: {
    id: 'short.axlesModel',
    defaultMessage: 'Axle model',
    description: ''
  },
  axlesBrand: {
    id: 'short.axlesBrand',
    defaultMessage: 'Axle brand',
    description: ''
  },
  beta: {
    id: 'short.beta',
    defaultMessage: 'BETA',
    description: 'BETA is a name do not translate'
  },
  brakeCount: {
    id: 'short.brakeCount',
    defaultMessage: 'Brake count',
    description: ''
  },
  brakePressure: {
    id: 'short.brakePressure',
    defaultMessage: 'Brake pressure',
    description: ''
  },
  brakePressureH2: {
    id: 'short.brakePressureH2',
    defaultMessage: 'Brake pressure H2',
    description: ''
  },
  brand: {
    id: 'short.brand',
    defaultMessage: 'Brand',
    description: ''
  },
  browser: {
    id: 'short.browser',
    defaultMessage: 'Browser',
    description: ''
  },
  checksum: {
    id: 'short.checksum',
    defaultMessage: 'Checksum',
    description: ''
  },
  circumference: {
    id: 'short.circumference',
    defaultMessage: 'Circumference',
    description: ''
  },
  code: {
    id: 'short.code',
    defaultMessage: 'Code',
    description: ''
  },
  columnName: {
    id: 'short.columnName',
    defaultMessage: 'Column | Category',
    description: ''
  },
  columnSettings: {
    id: 'short.columnSettings',
    defaultMessage: 'Column settings',
    description: ''
  },
  configure: {
    id: 'short.configure',
    defaultMessage: 'Configure',
    description: ''
  },
  copyToClipboard: {
    id: 'short.copyToClipboard',
    defaultMessage: 'Copy to clipboard',
    description: ''
  },
  copied: {
    id: 'short.copied',
    defaultMessage: 'Copied!',
    description: ''
  },
  critical: {
    id: 'short.critical',
    defaultMessage: 'Critical',
    description: ''
  },
  date: {
    id: 'short.date',
    defaultMessage: 'Date',
    description: ''
  },
  restoreDefaultWidths: {
    id: 'short.restoreDefaultWidths',
    defaultMessage: 'Restore default widths',
    description: ''
  },
  description: {
    id: 'short.description',
    defaultMessage: 'Description',
    description: ''
  },
  details: {
    id: 'short.details',
    defaultMessage: 'Details',
    description: ''
  },
  diagnosticEventCounter: {
    id: 'short.diagnosticEventCounter',
    defaultMessage: 'Diagnostic event counter',
    description: ''
  },
  distance: {
    id: 'short.distance',
    defaultMessage: 'Distance',
    description: ''
  },
  duration: {
    id: 'short.duration',
    defaultMessage: 'Duration',
    description: ''
  },
  ebsBrakeCalcNum: {
    id: 'short.ebsBrakeCalcNum',
    defaultMessage: 'EBS braking calc. num.',
    description: 'EBS Electronic Braking System.'
  },
  ebsPartNumber: {
    id: 'short.ebsPartNumber',
    defaultMessage: 'EBS part number',
    description: 'data label; EBS Electronic Braking System.'
  },
  ebsTimeVal: {
    id: 'short.ebsTimeVal',
    defaultMessage: '{value} at {time}',
    description:
      '{value} and {time} are variables and should not be translated. {value} will be a speed or distance. {time} will be a time'
  },
  ebsTireTimeVal: {
    id: 'short.ebsTireTimeVal',
    defaultMessage: 'Taken at {value}',
    description: '{value} is a variable and should not be translated. {value} will be a time.'
  },
  ebsSingleTireTimeVal: {
    id: 'short.ebsSingleTireTimeVal',
    defaultMessage: 'Wheel value last taken at {value}',
    description: '{value} is a variable and should not be translated. {value} will be a time.'
  },
  ebsType: {
    id: 'short.ebsType',
    defaultMessage: 'EBS type',
    description: 'EBS Electronic Braking System.'
  },
  iabsType: {
    id: 'short.iabsType',
    defaultMessage: 'ABS type',
    description: 'data label; ABS: Anti-lock Breaking System'
  },
  iabsPartNumber: {
    id: 'short.iabsPartNumber',
    defaultMessage: 'ABS part number',
    description: 'data label; ABS: Anti-lock Breaking System'
  },
  brakeLining: {
    id: 'short.brakeLining',
    defaultMessage: 'Brake lining',
    description: ''
  },
  country: {
    id: 'short.country',
    defaultMessage: 'Country',
    description: ''
  },
  region: {
    id: 'short.region',
    defaultMessage: 'Region',
    description: ''
  },
  city: {
    id: 'short.city',
    defaultMessage: 'City',
    description: ''
  },
  address: {
    id: 'short.address',
    defaultMessage: 'Address',
    description: 'Every place record has an address. An address is the particulars of the place'
  },
  email: {
    id: 'short.email',
    defaultMessage: 'Email',
    description: 'refers to an email address'
  },
  error: {
    id: 'short.error',
    defaultMessage: 'Error',
    description: ''
  },
  errorVal: {
    id: 'short.errorVal',
    defaultMessage: 'Error {value}',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  errorCode: {
    id: 'short.errorCode',
    defaultMessage: 'Error code',
    description: ''
  },
  eventList: {
    id: 'short.eventList',
    defaultMessage: 'Event list',
    description: ''
  },
  events: {
    id: 'short.events',
    defaultMessage: 'Events',
    description: ''
  },
  eventType: {
    id: 'short.eventType',
    defaultMessage: 'Event Type',
    description: ''
  },
  evolution: {
    id: 'short.evolution',
    defaultMessage: 'Evolution',
    description: ''
  },
  fax: {
    id: 'short.fax',
    defaultMessage: 'Fax',
    description: ''
  },
  filterMenu: {
    id: 'short.filterMenu',
    defaultMessage: 'Filter menu',
    description: ''
  },
  frequency: {
    id: 'short.frequency',
    defaultMessage: 'Frequency',
    description: ''
  },
  fullDescription: {
    id: 'short.fullDescription',
    defaultMessage: 'Full description',
    description: ''
  },
  histograms: {
    id: 'short.histograms',
    defaultMessage: 'Histograms',
    description: ''
  },
  histogramsSubtitle: {
    id: 'short.histogramsSubtitle',
    defaultMessage: 'Data for each trailer is accumulated over the full time of recording the trailer',
    description: ''
  },
  trailerDrivingProfile: {
    id: 'short.trailerDrivingProfile',
    defaultMessage: 'Trailer driving profile',
    description: 'Tab navigation label'
  },
  trailerDrivingProfileSubtitle: {
    id: 'short.trailerDrivingProfileSubtitle',
    defaultMessage:
      'Information about the trailer’s driving profile. Where indicated, values are normalised over 10,000 {value}. Average values are average industry values.',
    description: '{value} is a variable and should not be translated. {value} will be "km" or "mi"'
  },
  generalInfo: {
    id: 'short.generalInfo',
    defaultMessage: 'General info',
    description: ''
  },
  generalInformation: {
    id: 'short.generalInformation',
    defaultMessage: 'General information',
    description: ''
  },
  history: {
    id: 'short.history',
    defaultMessage: 'History',
    description: ''
  },
  hours: {
    id: 'short.hours',
    defaultMessage: 'hours',
    description: ''
  },
  hoursAgoVal: {
    id: 'short.hoursAgoVal',
    defaultMessage: '{value} hours ago',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  important: {
    id: 'short.important',
    defaultMessage: 'Important',
    description: ''
  },
  independentSuspension: {
    id: 'short.independentSuspension',
    defaultMessage: 'Independent suspension',
    description: ''
  },
  independentWheelSuspension: {
    id: 'short.independentWheelSuspension',
    defaultMessage: 'Independent wheel suspension',
    description: ''
  },
  info: {
    id: 'short.info',
    defaultMessage: 'Info',
    description: ''
  },
  installation: {
    id: 'short.installation',
    defaultMessage: 'Installation',
    description: ''
  },
  rfConfig: {
    id: 'short.rfConfig',
    defaultMessage: 'RF Configuration',
    description: 'RF is a name do not translate'
  },
  wheelLocation: {
    id: 'short.wheelLocation',
    defaultMessage: 'Wheel location',
    description: ''
  },
  sensorId: {
    id: 'short.sensorId',
    defaultMessage: 'Sensor ID',
    description: 'ID is a name do not translate'
  },
  installationDate: {
    id: 'short.installationDate',
    defaultMessage: 'Installation date',
    description: ''
  },
  location: {
    id: 'short.location',
    defaultMessage: 'Location',
    description: ''
  },
  trailerCategory: {
    id: 'short.trailerCategory',
    defaultMessage: 'Category',
    description: ''
  },
  functionalCategory: {
    id: 'short.functionalCategory',
    defaultMessage: 'Functional category',
    description: ''
  },
  invalidGPS: {
    id: 'short.invalidGPS',
    defaultMessage: 'Invalid GPS',
    description: 'GPS - Global Positioning System'
  },
  issue: {
    id: 'short.issue',
    defaultMessage: 'Issue',
    description: ''
  },
  lastPosition: {
    id: 'short.lastPosition',
    defaultMessage: 'Last position',
    description: ''
  },
  lastReport: {
    id: 'short.lastReport',
    defaultMessage: 'Last report',
    description: ''
  },
  latAcceleration: {
    id: 'short.latAcceleration',
    defaultMessage: 'Lat Acceleration',
    description: 'Lat abbreviation for lateral'
  },
  lift: {
    id: 'short.lift',
    defaultMessage: 'Lift',
    description: ''
  },
  light: {
    id: 'short.light',
    defaultMessage: 'Light',
    description: ''
  },
  list: {
    id: 'short.list',
    defaultMessage: 'List',
    description: ''
  },
  load: {
    id: 'short.load',
    defaultMessage: 'Load',
    description: ''
  },
  loaded: {
    id: 'short.loaded',
    defaultMessage: 'Loaded',
    description: ''
  },
  maxAxleLoad: {
    id: 'short.maxAxleLoad',
    defaultMessage: 'Max. axle load',
    description: ''
  },
  maxSpeed: {
    id: 'short.maxSpeed',
    defaultMessage: 'Max. speed',
    description: ''
  },
  mechanicalSuspension: {
    id: 'short.mechanicalSuspension',
    defaultMessage: 'Mechanical suspension',
    description: ''
  },
  mileage: {
    id: 'short.mileage',
    defaultMessage: 'Mileage',
    description: ''
  },
  mileageAtStart: {
    id: 'short.mileageAtStart',
    defaultMessage: 'Mileage at start',
    description: ''
  },
  mileageKm: {
    id: 'short.mileageKmt',
    defaultMessage: 'Mileage (km)',
    description: 'km abbreviation of unit of distance kilometer'
  },
  mileageMile: {
    id: 'short.mileageMile',
    defaultMessage: 'Mileage (mi)',
    description: 'mi abbreviation of unit of distance mile'
  },
  minAxleLoad: {
    id: 'short.minAxleLoad',
    defaultMessage: 'Min. axle load',
    description: 'min abbreviation of minimum'
  },
  model: {
    id: 'short.model',
    defaultMessage: 'Model',
    description: ''
  },
  module: {
    id: 'short.module',
    defaultMessage: 'Module',
    description: ''
  },
  move: {
    id: 'short.move',
    defaultMessage: 'Move',
    description: ''
  },
  name: {
    id: 'short.name',
    defaultMessage: 'Name',
    description: ''
  },
  add: {
    id: 'short.add',
    defaultMessage: 'Add',
    description: 'button label'
  },
  edit: {
    id: 'short.edit',
    defaultMessage: 'Edit',
    description: 'button label'
  },
  no: {
    id: 'short.no',
    defaultMessage: 'No',
    description: 'button label'
  },
  noData: {
    id: 'short.noData',
    defaultMessage: 'No data',
    description: ''
  },
  noIssue: {
    id: 'short.noIssue',
    defaultMessage: 'No issue',
    description: ''
  },
  none: {
    id: 'short.none',
    defaultMessage: 'None',
    description: ''
  },
  notifications: {
    id: 'short.notifications',
    defaultMessage: 'Notifications',
    description: ''
  },
  noNotifications: {
    id: 'short.noNotifications',
    defaultMessage: 'No notifications',
    description: ''
  },
  noSteeringAxle: {
    id: 'short.noSteeringAxle',
    defaultMessage: 'No steering axle',
    description: ''
  },
  notSupported: {
    id: 'short.notSupported',
    defaultMessage: 'Not supported',
    description: ''
  },
  notSupported32KbODR: {
    id: 'short.notSupported32KbODR',
    defaultMessage: 'Not supported (32 KB ODR)',
    description: 'KB abbreviation of kilobytes. ODR is a name do not translate'
  },
  noTrailer: {
    id: 'short.noTrailer',
    defaultMessage: 'No trailer',
    description: ''
  },
  notVisible: {
    id: 'short.notVisible',
    defaultMessage: 'Not visible',
    description: ''
  },
  numDaysVal: {
    id: 'short.numDaysVal',
    defaultMessage: '{value} days',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  odrEepromSize: {
    id: 'short.odrEepromSize',
    defaultMessage: 'ODR EEPROM Size',
    description: 'ODR and EEPROM are names do not translate'
  },
  ok: {
    id: 'short.ok',
    defaultMessage: 'OK',
    description: ''
  },
  oneDay: {
    id: 'short.oneDay',
    defaultMessage: '1 day',
    description: ''
  },
  oneHour: {
    id: 'short.oneHour',
    defaultMessage: '1 hour',
    description: ''
  },
  oneWeek: {
    id: 'short.oneWeek',
    defaultMessage: '1 week',
    description: ''
  },
  oneYearAgo: {
    id: 'short.oneYearAgo',
    defaultMessage: '1 year ago',
    description: ''
  },
  or: {
    id: 'short.or',
    defaultMessage: 'Or',
    description: ''
  },
  phone: {
    id: 'short.phone',
    defaultMessage: 'Phone',
    description: ''
  },
  pneumaticPressure: {
    id: 'short.pneumaticPressure',
    defaultMessage: 'Pneumatic pressure',
    description: ''
  },
  priority: {
    id: 'short.priority',
    defaultMessage: 'Priority',
    description: ''
  },
  premium: {
    id: 'short.premium',
    defaultMessage: 'Premium',
    description: ''
  },
  pressureCan: {
    id: 'short.pressureCan',
    defaultMessage: 'Pressure CAN',
    description: 'CAN is a name do not translate'
  },
  productionDate: {
    id: 'short.productionDate',
    defaultMessage: 'Production date',
    description: ''
  },
  recency: {
    id: 'short.recency',
    defaultMessage: 'Recency',
    description: ''
  },
  reset: {
    id: 'short.reset',
    defaultMessage: 'Reset',
    description: ''
  },
  submit: {
    id: 'short.submit',
    defaultMessage: 'Submit',
    description: ''
  },
  rigidAxle: {
    id: 'short.rigidAxle',
    defaultMessage: 'Rigid axle',
    description: ''
  },
  safe: {
    id: 'short.safe',
    defaultMessage: 'Safe',
    description: ''
  },
  score: {
    id: 'short.score',
    defaultMessage: 'Score',
    description: ''
  },
  gohc: {
    id: 'short.gohc',
    defaultMessage: 'EBS GIO',
    description: 'EBS Electronic Braking System.'
  },
  serviceEmpty: {
    id: 'short.serviceEmpty',
    defaultMessage: 'Recently serviced',
    description: ''
  },
  serviceSafe: {
    id: 'short.serviceSafe',
    defaultMessage: 'Service not due',
    description: ''
  },
  serviceWarning: {
    id: 'short.serviceWarning',
    defaultMessage: 'Service due',
    description: ''
  },
  serviceDanger: {
    id: 'short.serviceDanger',
    defaultMessage: 'Service overdue',
    description: ''
  },
  search: {
    id: 'short.search',
    defaultMessage: 'Search',
    description: ''
  },
  searchValue: {
    id: 'short.searchValue',
    defaultMessage: 'Search value',
    description: ''
  },
  sensor: {
    id: 'short.sensor',
    defaultMessage: 'Sensor',
    description: ''
  },
  show: {
    id: 'short.show',
    defaultMessage: 'Show',
    description: ''
  },
  showHideColumns: {
    id: 'short.showHideColumns',
    defaultMessage: 'Show / Hide columns',
    description: ''
  },
  singleTyre: {
    id: 'short.singleTyre',
    defaultMessage: 'Single tire',
    description: ''
  },
  singleTyres: {
    id: 'short.singleTyres',
    defaultMessage: 'Single tires',
    description: ''
  },
  speed: {
    id: 'short.speed',
    defaultMessage: 'Speed',
    description: ''
  },
  speedMph: {
    id: 'short.speedMph',
    defaultMessage: 'Speed (mph)',
    description: 'mph abbreviation of unit of speed miles per hour'
  },
  speedKmHr: {
    id: 'short.speedKmH',
    defaultMessage: 'Speed (km/hr)',
    description: 'km/hr abbreviation of unit of speed kilometers per hour'
  },
  altitude: {
    id: 'short.altitude',
    defaultMessage: 'Altitude',
    description: ''
  },
  measurementUnit: {
    id: 'short.measurementUnit',
    defaultMessage: '{var_measurement} ({var_unit})',
    description:
      '{var_measurement} and {var_unit} are variables and should not be translated. {var_measurement} is a number and {var_unit} is the unit of measurement'
  },
  standard: {
    id: 'short.standard',
    defaultMessage: 'Standard',
    description: ''
  },
  status: {
    id: 'short.status',
    defaultMessage: 'Status',
    description: ''
  },
  steeringAxle: {
    id: 'short.steeringAxle',
    defaultMessage: 'Steering axle',
    description: ''
  },
  supplyPressure: {
    id: 'short.supplyPressure',
    defaultMessage: 'Supply pressure',
    description: ''
  },
  supplyVoltage: {
    id: 'short.supplyVoltage',
    defaultMessage: 'Supply voltage',
    description: ''
  },
  suspensionType: {
    id: 'short.suspensionType',
    defaultMessage: 'Suspension type',
    description: ''
  },
  teeth: {
    id: 'short.teeth',
    defaultMessage: 'Teeth',
    description: 'teeth in an axle'
  },
  tenHours: {
    id: 'short.tenHours',
    defaultMessage: '10 hours',
    description: ''
  },
  time: {
    id: 'short.time',
    defaultMessage: 'Time',
    description: ''
  },
  today: {
    id: 'short.today',
    defaultMessage: 'Today',
    description: ''
  },
  yesterday: {
    id: 'short.yesterday',
    defaultMessage: 'Yesterday',
    description: ''
  },
  tyreChoice: {
    id: 'short.tyreChoice',
    defaultMessage: 'Tire choice',
    description: ''
  },
  tyreConfiguration: {
    id: 'short.tyreConfiguration',
    defaultMessage: 'Tire configuration',
    description: ''
  },
  tyres: {
    id: 'short.tyres',
    defaultMessage: 'Tires',
    description: ''
  },
  tyresModel: {
    id: 'short.tyresModel',
    defaultMessage: 'Tire model',
    description: ''
  },
  tyresBrand: {
    id: 'short.tyresBrand',
    defaultMessage: 'Tire brand',
    description: ''
  },
  trailer: {
    id: 'short.trailer',
    defaultMessage: 'Trailer',
    description: ''
  },
  trailerName: {
    id: 'short.trailerName',
    defaultMessage: 'Trailer Name',
    description: ''
  },
  trailerCode: {
    id: 'short.trailerCode',
    defaultMessage: 'Trailer Code',
    description: ''
  },
  trailerType: {
    id: 'short.trailerType',
    defaultMessage: 'Trailer type',
    description: ''
  },
  trailerBrand: {
    id: 'short.trailerBrand',
    defaultMessage: 'Trailer brand',
    description: ''
  },
  trailerList: {
    id: 'short.trailerList',
    defaultMessage: 'Trailer list',
    description: ''
  },
  trailers: {
    id: 'short.trailers',
    defaultMessage: 'Trailers',
    description: ''
  },
  trip: {
    id: 'short.trip',
    defaultMessage: 'Trip',
    description: ''
  },
  trips: {
    id: 'short.trips',
    defaultMessage: 'Trips',
    description: ''
  },
  twinTyres: {
    id: 'short.twinTyres',
    defaultMessage: 'Twin tires',
    description: ''
  },
  twoDays: {
    id: 'short.twoDays',
    defaultMessage: '2 days',
    description: ''
  },
  fortyFiveDays: {
    id: 'short.fortyFiveDays',
    defaultMessage: '45 days',
    description: ''
  },
  ninetyDays: {
    id: 'short.ninetyDays',
    defaultMessage: '90 days',
    description: ''
  },
  type: {
    id: 'short.type',
    defaultMessage: 'Type',
    description: ''
  },
  unknown: {
    id: 'short.unknown',
    defaultMessage: 'Unknown',
    description: ''
  },
  unknownDescription: {
    id: 'short.unknownDescription',
    defaultMessage: 'Unknown description',
    description: ''
  },
  unknownStatus: {
    id: 'short.unknownStatus',
    defaultMessage: 'Unknown status',
    description: ''
  },
  unknownVal: {
    id: 'short.unknownVal',
    defaultMessage: 'Unknown ({value})',
    description:
      '{value} is a variable and should not be translated. {value} will be a string that is missing from the current translations.'
  },
  vehicleType: {
    id: 'short.vehicleType',
    defaultMessage: 'Vehicle type',
    description: ''
  },
  visible: {
    id: 'short.visible',
    defaultMessage: 'Visible',
    description: ''
  },
  warning: {
    id: 'short.warning',
    defaultMessage: 'Warning',
    description: ''
  },
  website: {
    id: 'short.website',
    defaultMessage: 'Website',
    description: ''
  },
  web: {
    id: 'short.web',
    defaultMessage: 'Web',
    description: 'web abbreviation for website'
  },
  width: {
    id: 'short.width',
    defaultMessage: 'Width',
    description: ''
  },
  yes: {
    id: 'short.yes',
    defaultMessage: 'Yes',
    description: ''
  },
  last2h: {
    id: 'short.last2h',
    defaultMessage: 'Last 2h',
    description: 'data table column header. h abbreviation of hour'
  },
  last6h: {
    id: 'short.last6h',
    defaultMessage: 'Last 6h',
    description: 'h abbreviation of hour'
  },
  last12h: {
    id: 'short.last12h',
    defaultMessage: 'Last 12h',
    description: 'h abbreviation of hour'
  },
  last24h: {
    id: 'short.last24h',
    defaultMessage: 'Last 24h',
    description: 'h abbreviation of hour'
  },
  last48h: {
    id: 'short.last48h',
    defaultMessage: 'Last 48h',
    description: 'h abbreviation of hour'
  },
  alarm2h: {
    id: 'short.alarm2h',
    defaultMessage: 'Alarm 2h',
    description: 'h abbreviation of hour'
  },
  placesAlarms2h: {
    id: 'short.placesAlarms2h',
    defaultMessage: 'Places 2h',
    description: 'h abbreviation of hour'
  },
  alarms: {
    id: 'short.alarms',
    defaultMessage: 'Alarms',
    description: ''
  },
  emailPreview: {
    id: 'short.emailPreview',
    defaultMessage: 'Email preview',
    description: ''
  },
  noPreview: {
    id: 'short.noPreview',
    defaultMessage: 'No preview available',
    description: ''
  },
  context: {
    id: 'short.context',
    defaultMessage: 'Context',
    description: ''
  },
  odrParsingStatus: {
    id: 'short.odrParsingStatus',
    defaultMessage: 'ODR status',
    description: 'ODR is a name do not translate'
  },
  odometer: {
    id: 'short.odometer',
    defaultMessage: 'Odometer',
    description: ''
  },
  activity: {
    id: 'short.activity',
    defaultMessage: 'Activity',
    description: ''
  },
  driving: {
    id: 'short.driving',
    defaultMessage: 'Driving',
    description: ''
  },
  paused: {
    id: 'short.paused',
    defaultMessage: 'Paused',
    description: ''
  },
  parked: {
    id: 'short.parked',
    defaultMessage: 'Parked',
    description: ''
  },
  ordFiles: {
    id: 'short.odrFiles',
    defaultMessage: 'ODR Files',
    description: 'ODR is a name do not translate'
  },
  fileId: {
    id: 'short.fileId',
    defaultMessage: 'File Id',
    description: ''
  },
  fileSize: {
    id: 'short.fileSize',
    defaultMessage: 'File Size',
    description: ''
  },
  download: {
    id: 'short.download',
    defaultMessage: 'Download',
    description: ''
  },
  extraInfo: {
    id: 'short.extraInfo',
    defaultMessage: 'Extra Info',
    description: ''
  },
  longitude: {
    id: 'short.longitude',
    defaultMessage: 'Longitude',
    description: ''
  },
  latitude: {
    id: 'short.latitude',
    defaultMessage: 'Latitude',
    description: ''
  },
  ABSevents: {
    id: 'short.ABSevents',
    defaultMessage: 'ABS event(s)',
    description: 'ABS - Automating braking system'
  },
  followUnfollowTrailer: {
    id: 'short.followUnfollowTrailer',
    defaultMessage: 'Follow/Unfollow trailer',
    description: ''
  },
  decoded: {
    id: 'short.decoded',
    defaultMessage: 'Decoded',
    description: ''
  },
  reload: {
    id: 'short.reload',
    defaultMessage: 'Reload',
    description: ''
  },
  alert: {
    id: 'short.alert',
    defaultMessage: 'Alert',
    description: ''
  },
  alertCategories: {
    id: 'short.alertCategories',
    defaultMessage: 'Alert categories',
    description: ''
  },
  batteryLevel: {
    id: 'short.batteryLevel',
    defaultMessage: 'Battery Level',
    description: ''
  },
  low: {
    id: 'short.low',
    defaultMessage: 'Low',
    description: ''
  },
  medium: {
    id: 'short.medium',
    defaultMessage: 'Medium',
    description: 'medium, as in an average level between high and low'
  },
  high: {
    id: 'short.high',
    defaultMessage: 'High',
    description: ''
  },
  dead: {
    id: 'short.dead',
    defaultMessage: 'Empty',
    description: ''
  },
  singleHyphen: {
    id: 'short.singleHyphen',
    defaultMessage: '-',
    description: ''
  },
  tpms: {
    id: 'short.tpms',
    defaultMessage: 'TPMS',
    description: 'TPMS - tire pressure monitoring system'
  },
  tpmsIssue: {
    id: 'short.tpmsIssue',
    defaultMessage: 'TPMS Issue',
    description: 'TPMS - tire pressure monitoring system'
  },
  brakePads: {
    id: 'short.brakePads',
    defaultMessage: 'Brake Pads',
    description: ''
  },
  kilometersFull: {
    id: 'short.kilometersFull',
    defaultMessage: 'kilometers',
    description: 'plural of distance measurement kilometer'
  },
  milesFull: {
    id: 'short.milesFull',
    defaultMessage: 'miles',
    description: 'plural of distance measurement mile'
  },
  kilometerFull: {
    id: 'short.kilometerFull',
    defaultMessage: 'kilometer',
    description: 'singular of distance measurement kilometer'
  },
  mileFull: {
    id: 'short.mileFull',
    defaultMessage: 'mile',
    description: 'singular of distance measurement mile'
  },
  kmVal: {
    id: 'short.kmVal',
    defaultMessage: '{value} km',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  metersVal: {
    id: 'short.metersVal',
    defaultMessage: '{value} m',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  feetVal: {
    id: 'short.feetVal',
    defaultMessage: '{value} ft',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  mileVal: {
    id: 'short.mileVal',
    defaultMessage: '{value} mi',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  kmSpeedVal: {
    id: 'short.kmSpeedVal',
    defaultMessage: '{value} km/h',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  mileSpeedVal: {
    id: 'short.mileSpeedVal',
    defaultMessage: '{value} mph',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  tonVal: {
    id: 'short.tonVal',
    defaultMessage: '{value} t',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  kiloPoundUSAVal: {
    id: 'abbrev.kiloPoundUSAVal',
    defaultMessage: '{value} k',
    description:
      '{value} is a variable and should not be translated. {value} will be a number. k is the abbreviation weight measurement kilo pound which is used in USA for 1000 pounds'
  },
  millimeterVal: {
    id: 'short.millimeterVal',
    defaultMessage: '{value} mm',
    description:
      '{value} is a variable and should not be translated. {value} will be a number. mm is abbreviation of unit of length millimeter'
  },
  centimeterVal: {
    id: 'short.centimeterVal',
    defaultMessage: '{value} cm',
    description:
      '{value} is a variable and should not be translated. {value} will be a number. cm is abbreviation of unit of length centimeter'
  },
  inchVal: {
    id: 'short.inchVal',
    defaultMessage: '{value} in',
    description:
      '{value} is a variable and should not be translated. {value} will be a number. in is abbreviation of unit of length inch'
  },
  celciusVal: {
    id: 'short.celciusVal',
    defaultMessage: '{value} °C',
    description:
      '{value} is a variable and should not be translated. {value} will be a number. °C is abbreviation of unit of temperature degrees celsius'
  },
  fahrenheitVal: {
    id: 'short.fahrenheitVal',
    defaultMessage: '{value} °F',
    description:
      '{value} is a variable and should not be translated. {value} will be a number. °F is abbreviation of unit of temperature degrees fahrenheit'
  },
  pressureBarVal: {
    id: 'short.pressureBarVal',
    defaultMessage: '{value} bar',
    description:
      '{value} is a variable and should not be translated. {value} will be a number. bar is the metric unit pressure'
  },
  pressurePsiVal: {
    id: 'short.pressurePsiVal',
    defaultMessage: '{value} psi',
    description:
      '{value} is a variable and should not be translated. {value} will be a number. psi is the imperial unit pressure'
  },
  voltageVal: {
    id: 'short.voltageVal',
    defaultMessage: '{value} V',
    description: '{value} is a variable and should not be translated. {value} will be a number. v is the unit voltage'
  },
  exportCSV: {
    id: 'short.exportCSV',
    defaultMessage: 'Export CSV',
    description: 'CVS is a file type'
  },
  ton: {
    id: 'short.ton',
    defaultMessage: 'Ton',
    description: 'weight tom'
  },
  tons: {
    id: 'short.tons',
    defaultMessage: 'Tons',
    description: 'weight plural tons'
  },
  binSize: {
    id: 'short.binSize',
    defaultMessage: 'Bin size',
    description: ''
  },
  threshold: {
    id: 'short.threshold',
    defaultMessage: 'Threshold',
    description: ''
  },
  loading: {
    id: 'short.loading',
    defaultMessage: 'Loading',
    description: ''
  },
  moreAreLoading: {
    id: 'short.moreAreLoading',
    defaultMessage: 'More are loading',
    description: ''
  },
  unloading: {
    id: 'short.unloading',
    defaultMessage: 'Unloading',
    description: ''
  },
  startTime: {
    id: 'short.startTime',
    defaultMessage: 'Start Time',
    description: ''
  },
  tripInfo: {
    id: 'short.tripInfo',
    defaultMessage: 'Trip Info',
    description: ''
  },
  emptyTransport: {
    id: 'short.emptyTransport',
    defaultMessage: 'Empty Transport',
    description: ''
  },
  loadingLevels: {
    id: 'short.loadingLevels',
    defaultMessage: 'Loading Levels',
    description: ''
  },
  utilizationCalendar: {
    id: 'short.utilizationCalendar',
    defaultMessage: 'Utilization Calendar',
    description: ''
  },
  weekly: {
    id: 'short.weekly',
    defaultMessage: 'Weekly',
    description: ''
  },
  monthly: {
    id: 'short.monthly',
    defaultMessage: 'Monthly',
    description: ''
  },
  view: {
    id: 'short.view',
    defaultMessage: 'View',
    description: ''
  },
  shareCurrentView: {
    id: 'short.shareCurrentView',
    defaultMessage: 'Share current view',
    description: ''
  },
  shareView: {
    id: 'short.shareView',
    defaultMessage: 'Share View',
    description: ''
  },
  shareSelection: {
    id: 'short.shareSelection',
    defaultMessage: 'Share selection',
    description: ''
  },
  shareSelectionTooltip: {
    id: 'short.shareSelectionTooltip',
    defaultMessage: 'Share link to view selected trailers',
    description: ''
  },
  exportTrailerSnapshot: {
    id: 'short.exportTrailerSnapshot',
    defaultMessage: 'Export trailer snapshot',
    description: ''
  },
  good: {
    id: 'short.good',
    defaultMessage: 'Good',
    description: ''
  },
  bad: {
    id: 'short.bad',
    defaultMessage: 'Bad',
    description: ''
  },
  activateSelectionTool: {
    id: 'short.activateSelectionTool',
    defaultMessage: 'Select trailers (SHIFT+Drag)',
    description: ''
  },
  pressure: {
    id: 'short.pressure',
    defaultMessage: 'Pressure',
    description: ''
  },
  heightWithSymbol: {
    id: 'short.heightWithSymbol',
    defaultMessage: 'Height Δ',
    description: ''
  },
  means45PercOrAbove: {
    id: 'short.means45PercOrAbove',
    defaultMessage: 'means Braking Performance Value of 45% or above',
    description: ''
  },
  meansBelow45Perc: {
    id: 'short.meansBelow45Perc',
    defaultMessage: 'means Braking Performance Value of below 45%',
    description: ''
  },
  meansInsufficientData: {
    id: 'short.meansInsufficientData',
    defaultMessage: 'means insufficient data to provide a Braking Performance Value.',
    description: ''
  },
  valueGreyedOut: {
    id: 'short.meansInsufvalueGreyedOutficientData',
    defaultMessage: 'Value is greyed if last known value is older than today',
    description: 'greyed refers to a visual effect of coloring grey to depict old or disabled'
  },
  lastStatusTrailer: {
    id: 'short.lastStatusTrailer',
    defaultMessage: 'Last known braking performance status.',
    description: ''
  },
  predictedBPV: {
    id: 'short.predictedBPV',
    defaultMessage: 'Predicted Braking Performance Value based on valid brake events within last 45 or 90 days.',
    description: ''
  },
  valueIs: {
    id: 'short.valueIs',
    defaultMessage: 'Value is',
    description: ''
  },
  ifLastOlderThanYesterday: {
    id: 'short.ifLastOlderThanYesterday',
    defaultMessage: 'if last known value if older than today',
    description: ''
  },
  greyed: {
    id: 'short.greyed',
    defaultMessage: 'greyed',
    description: 'greyed refers to a visual effect of coloring grey to depict old or disabled'
  },
  on: {
    id: 'short.on',
    defaultMessage: 'on',
    description: ''
  },
  onCamelCase: {
    id: 'short.onCamelCase',
    defaultMessage: 'On',
    description: ''
  },
  offCamelCase: {
    id: 'short.offCamelCase',
    defaultMessage: 'Off',
    description: ''
  },
  from: {
    id: 'short.from',
    defaultMessage: 'From',
    description: ''
  },
  to: {
    id: 'short.to',
    defaultMessage: 'To',
    description: ''
  },
  more: {
    id: 'short.more',
    defaultMessage: 'More',
    description: ''
  },
  moreInfo: {
    id: 'short.moreInfo',
    defaultMessage: 'More Info',
    description: ''
  },
  clickMoreInfo: {
    id: 'short.clickMoreInfo',
    defaultMessage: 'Click for More Info',
    description: ''
  },
  weight: {
    id: 'short.weight',
    defaultMessage: 'Weight',
    description: ''
  },
  temperature: {
    id: 'short.temperature',
    defaultMessage: 'Temperature',
    description: ''
  },
  customise: {
    id: 'short.customise',
    defaultMessage: 'Customise',
    description: ''
  },
  sectionName: {
    id: 'short.sectionName',
    defaultMessage: 'Section Name',
    description: ''
  },
  loadingIndictor: {
    id: 'short.loadingIndictor',
    defaultMessage: 'Loading...',
    description: ''
  },
  loadingIndictorWithParenthesis: {
    id: 'short.loadingIndictorWithParenthesis',
    defaultMessage: ' (loading...)',
    description: ''
  },
  currentWindowSize: {
    id: 'short.currentWindowSize',
    defaultMessage: 'Current Window Size:',
    description: ''
  },
  createReportHere: {
    id: 'short.createReportHere',
    defaultMessage: 'Create your ‘Braking Performance Report’ here',
    description: ''
  },
  downloadFromHistory: {
    id: 'short.downloadFromHistory',
    defaultMessage: 'Download from History',
    description: ''
  },
  reportDate: {
    id: 'short.reportDate',
    defaultMessage: 'Report date:',
    description: ''
  },
  createAndDownload: {
    id: 'short.createAndDownload',
    defaultMessage: 'Create & Download',
    description: ''
  },
  windowSizeStart: {
    id: 'short.windowSizeStart',
    defaultMessage: 'Window Size',
    description: ''
  },
  widowSizeContinued: {
    id: 'short.widowSizeContinued',
    defaultMessage: 'is the moving period of time used to produce the ‘Braking Performance Value’',
    description: ''
  },
  valTrailersSelected: {
    id: 'short.valTrailersSelected',
    defaultMessage: '{VAR_COUNT} Trailers Selected',
    description: '{VAR_COUNT} is a variable and should not be translated. {VAR_COUNT} will be a number.'
  },
  emailIfTurnToFail: {
    id: 'short.emailIfTurnToFail',
    defaultMessage: "Send an email notification if a trailer's 'Status' changes from 'Pass' to 'Low performance'",
    description: 'Label of a switch'
  },
  emailIf7Days: {
    id: 'short.emailIf7Days',
    defaultMessage:
      "Send an email notification reminder if a trailer's 'Status' remains as 'Low performance' for 7 consecutive calendar days",
    description: 'Label of a switch'
  },
  emailIf14Days: {
    id: 'short.emailIf14Days',
    defaultMessage:
      "Send an email notification reminder if a trailer's 'Status' remains as 'Low performance' for 14 consecutive calendar days",
    description: ''
  },
  emailIfActionRequired: {
    id: 'short.emailIfActionRequired',
    defaultMessage: "Send an email notification reminder if a trailer's 'Status' changes to 'Action Required'",
    description: ''
  },
  emailNotications: {
    id: 'short.emailNotications',
    defaultMessage: 'Email notifications',
    description: ''
  },
  orSelectInTable: {
    id: 'short.orSelectInTable',
    defaultMessage: 'Or select in the table :',
    description: ''
  },
  deselectAll: {
    id: 'short.deselectAll',
    defaultMessage: 'Deselect All',
    description: ''
  },
  selectAll: {
    id: 'short.selectAll',
    defaultMessage: 'Select All',
    description: ''
  },
  groupNameVar: {
    id: 'short.groupNameVar',
    defaultMessage: '{VAR_GROUP_NAME} (Group)',
    description: '{VAR_GROUP_NAME} is a variable and should not be translated. {VAR_GROUP_NAME} is a string.'
  },
  pressureThresholdEOP: {
    id: 'short.pressureThresholdEOP',
    defaultMessage: 'Extreme over pressure',
    description: ''
  },
  pressureThresholdOP: {
    id: 'short.pressureThresholdOP',
    defaultMessage: 'Over pressure',
    description: ''
  },
  pressureThresholdUP: {
    id: 'short.pressureThresholdUP',
    defaultMessage: 'Under pressure',
    description: ''
  },
  pressureThresholdEUP: {
    id: 'short.pressureThresholdEUP',
    defaultMessage: 'Extreme under pressure',
    description: ''
  },
  pressureThresholdUNK: {
    id: 'short.pressureThresholdUNK',
    defaultMessage: 'Unknown pressure',
    description: ''
  },
  pleaseTryAgainLater: {
    id: 'short.pleaseTryAgainLater',
    defaultMessage: 'Please try again later',
    description: ''
  },
  backToPortal: {
    id: 'short.backToPortal',
    defaultMessage: 'Back to portal',
    description: ''
  },
  confirm: {
    id: 'short.confirm',
    defaultMessage: 'Confirm',
    description: ''
  },
  trailerlist: {
    id: 'short.trailerlist',
    defaultMessage: 'Trailer list',
    description: ''
  },
  trailerlistFilteredByAlarm: {
    id: 'short.trailerlistFilteredByAlarm',
    defaultMessage: 'Trailer list filtered by alarm ({alarms})',
    description: '{alarms} is a variable do not translate'
  },
  trailerlistFilteredByState: {
    id: 'short.trailerlistFilteredByState',
    defaultMessage: 'Trailer list filtered by activity ({states})',
    description: '{states} is a variable do not translate'
  },
  trailerlistFilteredByStateAlarm: {
    id: 'short.trailerlistFilteredByStateAlarm',
    defaultMessage: 'Trailer list filtered by activity ({states}) and alarm ({alarms})',
    description: ''
  },
  trailerlistFilteredByHealthStatus: {
    id: 'short.trailerlistFilteredByHealthStatus',
    defaultMessage: 'Trailer list filtered by health status ({states})',
    description: '{states} is a variable do not translate'
  },
  fcfLabels: {
    id: 'short.fcfLabels',
    defaultMessage: 'FCF Labels',
    description: 'FCF is a name do not translate'
  },
  editLabels: {
    id: 'short.editLabels',
    defaultMessage: 'edit labels',
    description: ''
  },
  diagramName: {
    id: 'short.diagramName',
    defaultMessage: 'Diagram Name',
    description: ''
  },
  paginationSingleItem: {
    id: 'short.paginationSingleItem',
    defaultMessage: 'Result 1 - 1 of 1 on 1 page',
    description: ''
  },
  paginationSingular: {
    id: 'short.paginationSingular',
    defaultMessage: 'Results {VAR_RANGE_1} - {VAR_RANGE_2} of {VAR_TOTAL} on 1 page',
    description:
      '{VAR_RANGE_1}, {VAR_RANGE_2} and {VAR_TOTAL} are variables and should not be translated. {VAR_RANGE_1}, {VAR_RANGE_2} and {VAR_TOTAL} are numbers.'
  },
  paginationPlural: {
    id: 'short.paginationPlural',
    defaultMessage: 'Results {VAR_RANGE_1} - {VAR_RANGE_2} of {VAR_TOTAL} on {VAR_PAGE} pages',
    description:
      '{VAR_RANGE_1}, {VAR_RANGE_2} and {VAR_TOTAL} are variables and should not be translated. {VAR_RANGE_1}, {VAR_RANGE_2} and {VAR_TOTAL} are numbers.'
  },
  licensePlate: {
    id: 'short.licensePlate',
    defaultMessage: 'Licence Plate',
    description: ''
  },
  lastValidEbpmsMessage: {
    id: 'short.lastValidEbpmsMessage',
    defaultMessage: 'Last Braking',
    description: ''
  },
  tpmsSource: {
    id: 'short.tpmsSource',
    defaultMessage: 'TPMS source',
    description: 'TPMS - tire pressure monitoring system'
  },
  vin: {
    id: 'short.vin',
    defaultMessage: 'VIN',
    description: 'Vehicle identification number'
  },
  trailerProductionDate: {
    id: 'short.trailerProductionDate',
    defaultMessage: 'Trailer production date',
    description: ''
  },
  faultCode: {
    id: 'short.faultCode',
    defaultMessage: 'Fault Code',
    description: ''
  },
  readMore: {
    id: 'short.readMore',
    defaultMessage: 'read more',
    description: ''
  },
  faultCodeVal: {
    id: 'short.faultCodeVal',
    defaultMessage: 'Fault code {VAR_FAULT_CODE}',
    description: '{VAR_FAULT_CODE} is a variable and should not be translated. {VAR_FAULT_CODE} is a number.'
  },
  actions: {
    id: 'short.actions',
    defaultMessage: 'Actions',
    description: ''
  },
  zoomIn: {
    id: 'short.zoomIn',
    defaultMessage: 'Zoom In',
    description: ''
  },
  zoomOut: {
    id: 'short.zoomOut',
    defaultMessage: 'Zoom Out',
    description: ''
  },
  bulkSelectTrailers: {
    id: 'short.bulkSelectTrailers',
    defaultMessage: 'Bulk select trailers',
    description: ''
  },
  navigateMap: {
    id: 'short.navigateMap',
    defaultMessage: 'Navigate map',
    description: ''
  },
  showHideAlarms: {
    id: 'short.showHideAlarms',
    defaultMessage: 'Show / hide alarms',
    description: ''
  },
  showHideGeofences: {
    id: 'short.showHideGeofences',
    defaultMessage: 'Show / hide places',
    description: 'Tooltip for a switch'
  },
  showWabcoLogo: {
    id: 'short.showWabcoLogo',
    defaultMessage: 'Show / hide Wabco Service Partner',
    description: 'Wabco is a name do not translate'
  },
  pin: {
    id: 'short.pin',
    defaultMessage: 'Pin',
    description: 'Pin on a map to point out a location'
  },
  pinned: {
    id: 'short.pinned',
    defaultMessage: 'pinned',
    description: 'a location pointed out on a map'
  },
  unpinned: {
    id: 'short.unpinned',
    defaultMessage: 'unpinned',
    description: 'remove a location pointed out on a map'
  },
  fixedSet: {
    id: 'short.fixedSet',
    defaultMessage: 'Currently displayed - Fixed columns',
    description: ''
  },
  fixedSetSub: {
    id: 'short.fixedSetSub',
    defaultMessage: 'When scrolling horizontally these columns will be fixed on the left hand-side of the table',
    description: ''
  },
  scrollableSet: {
    id: 'short.scrollableSet',
    defaultMessage: 'Currently displayed - Scrollable columns',
    description: ''
  },
  scrollableSetSub: {
    id: 'short.scrollableSetSub',
    defaultMessage: 'Scroll through these columns horizontally to see more data',
    description: ''
  },
  standardSet: {
    id: 'short.standardSet',
    defaultMessage: 'Standard set',
    description: ''
  },
  groups: {
    id: 'short.groups',
    defaultMessage: 'Groups',
    description: ''
  },
  recentlyServiced: {
    id: 'short.recentlyServiced',
    defaultMessage: 'recently serviced',
    description: ''
  },
  hrsLeftTillService: {
    id: 'short.hrsLeftTillService',
    defaultMessage: '{value} hrs left',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  hrsOverdueService: {
    id: 'short.hrsOverdueService',
    defaultMessage: '{value} hrs overdue',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  selectDifferentDate: {
    id: 'short.selectDifferentDate',
    defaultMessage: 'Select different date',
    description: ''
  },
  start: {
    id: 'short.start',
    defaultMessage: 'Start',
    description: ''
  },
  end: {
    id: 'short.end',
    defaultMessage: 'End',
    description: ''
  },
  noAltitudeVal: {
    id: 'short.noAltitude',
    defaultMessage: 'Altitude: --',
    description: 'Empty value for Altitude: X m'
  },
  noSpeedVal: {
    id: 'short.noSpeedVal',
    defaultMessage: 'Speed: --',
    description: 'Empty value for Speed: X km/h'
  },
  customerName: {
    id: 'short.customerName',
    defaultMessage: 'Customer name',
    description: ''
  },
  hoursSinceService: {
    id: 'short.hoursSinceService',
    defaultMessage: 'Hours since last service',
    description: 'data label'
  },
  hoursUntilService: {
    id: 'short.hoursUntilService',
    defaultMessage: 'Hours until next service',
    description: 'data label'
  },
  mapStyleURL: {
    id: 'short.mapStyleURL',
    defaultMessage: 'Map Style URL',
    description: ''
  },
  mapAccessToken: {
    id: 'short.mapAccessToken',
    defaultMessage: 'Map Access Token',
    description: ''
  },
  liveMap: {
    id: 'short.liveMap',
    defaultMessage: 'Map view',
    description: 'Label in navigation'
  },
  health: {
    id: 'short.health',
    defaultMessage: 'Health',
    description: 'Label in navigation'
  },
  displayInformation: {
    id: 'short.displayInformation',
    defaultMessage: 'Show/hide alarms and places',
    description: 'Navigation label & tooltip'
  },
  showInformation: {
    id: 'short.showInformation',
    defaultMessage: 'Show information',
    description: 'Navigation label & tooltip'
  },
  showHideAlarmsPlaces: {
    id: 'short.showHideAlarmsPlaces',
    defaultMessage: 'Show/hide alarms and places',
    description: 'Navigation label & tooltip'
  },
  drivingBehaviour: {
    id: 'short.drivingBehaviour',
    defaultMessage: 'Driving behaviour',
    description: ''
  },
  doubleDash: {
    id: 'short.doubleDash',
    defaultMessage: '--',
    description: 'empty value'
  },
  unqualified: {
    id: 'short.unqualified',
    defaultMessage: 'Unqualified',
    description: 'a status, not qualified'
  },
  gatheringData: {
    id: 'short.gatheringData',
    defaultMessage: 'Gathering data',
    description: 'a status, not enough data to calculate'
  },
  lowPerformance: {
    id: 'short.lowPerformance',
    defaultMessage: 'Low performance',
    description: 'a status, bad'
  },
  actionRequired: {
    id: 'short.actionRequired',
    defaultMessage: 'Action Required',
    description: 'a status, bad'
  },
  placeName: {
    id: 'short.placeName',
    defaultMessage: 'Place name',
    description: 'data label; name of a place of interest on a map'
  },
  trailerEntersNotification: {
    id: 'short.trailerEntersNotification',
    defaultMessage: 'Trailer enters notification',
    description: 'notification created when a trailer enters a place, a place is an area on the map'
  },
  trailerLeavesNotification: {
    id: 'short.trailerLeavesNotification',
    defaultMessage: 'notification created when a trailer leaves a place, a place is an area on the map',
    description: 'geofence is an area on a map'
  },
  hideDashboard: {
    id: 'short.hideDashboard',
    defaultMessage: 'Hide dashboard',
    description: 'Tooltip on a switch that shows/hides part of the UI'
  },
  showDashboard: {
    id: 'short.showDashboard',
    defaultMessage: 'Show dashboard',
    description: 'Tooltip on a switch that shows/hides part of the UI'
  },
  columns: {
    id: 'short.columns',
    defaultMessage: 'Columns',
    description: ''
  },
  selectionSettings: {
    id: 'short.selectionSettings',
    defaultMessage: 'Selection settings',
    description: ''
  },
  wheelSpeedSensor: {
    id: 'short.wheelSpeedSensor',
    defaultMessage: 'Wheel speed sensor',
    description: 'Sensor that measures speed on a trailer wheel'
  },
  notification: {
    id: 'short.notification',
    defaultMessage: 'Notification',
    description: ''
  },
  category: {
    id: 'short.category',
    defaultMessage: 'Category',
    description: ''
  },
  up: {
    id: 'short.up',
    defaultMessage: 'Up',
    description: ''
  },
  down: {
    id: 'short.down',
    defaultMessage: 'Down',
    description: ''
  },
  neutral: {
    id: 'short.neutral',
    defaultMessage: 'Neutral',
    description: ''
  },
  subscribe: {
    id: 'short.subscribe',
    defaultMessage: 'Subscribe'
  },
  unSubscribe: {
    id: 'short.unSubscribe',
    defaultMessage: 'Unsubscribe'
  },
  general: {
    id: 'short.general',
    defaultMessage: 'General'
  },
  subscription: {
    id: 'short.subscription',
    defaultMessage: 'Subscription'
  },
  at: {
    id: 'short.at',
    defaultMessage: 'at',
    description: 'this will be used between a day and a time eg. Wednesday at 9:00'
  },
  daily: {
    id: 'short.daily',
    defaultMessage: 'Daily',
    description: ''
  },
  clearSelection: {
    id: 'short.clearSelection',
    defaultMessage: 'Clear Selection'
  },
  aggregateLoad: {
    id: 'short.aggregateLoad',
    defaultMessage: 'Aggregate load'
  },
  admin: {
    id: 'short.admin',
    defaultMessage: 'Admin'
  },
  places: {
    id: 'short.places',
    defaultMessage: 'Places',
    description: 'Title for places, which are areas of interest on the map defined by users'
  },
  place: {
    id: 'short.place',
    defaultMessage: 'Place',
    description: 'Title for place, which are areas of interest on the map defined by users'
  },
  enter: {
    id: 'short.enter',
    defaultMessage: 'Enter',
    description: 'To travel into a place'
  },
  leave: {
    id: 'short.leave',
    defaultMessage: 'Leave',
    description: 'To travel out of a place'
  },
  account: {
    id: 'short.account',
    defaultMessage: 'Account',
    description: 'A user account, the use their account to be able to sign into the application'
  },
  minsVal: {
    id: 'short.minsVal',
    defaultMessage: '{VAR_AMOUNT} mins',
    description: 'mins is short for minutes. Do not translate VAR_AMOUNT, it is a variable, it will be a number.'
  },
  hrsVal: {
    id: 'short.hrsVal',
    defaultMessage: '{VAR_AMOUNT} hrs',
    description: 'hrs is short for hours. Do not translate VAR_AMOUNT, it is a variable, it will be a number.'
  },
  wheel: {
    id: 'short.wheel',
    defaultMessage: 'Wheel',
    description: ''
  },
  first: {
    id: 'short.first',
    defaultMessage: 'first',
    description: ''
  },
  second: {
    id: 'short.second',
    defaultMessage: 'second',
    description: ''
  },
  third: {
    id: 'short.third',
    defaultMessage: 'third',
    description: ''
  },
  fourth: {
    id: 'short.fourth',
    defaultMessage: 'fourth',
    description: ''
  },
  fifth: {
    id: 'short.fifth',
    defaultMessage: 'fifth',
    description: ''
  },
  sixth: {
    id: 'short.sixth',
    defaultMessage: 'sixth',
    description: ''
  },
  left: {
    id: 'short.left',
    defaultMessage: 'left',
    description: ''
  },
  right: {
    id: 'short.right',
    defaultMessage: 'right',
    description: ''
  },
  airSuspensionEcas1: {
    id: 'short.airSuspensionEcas1',
    defaultMessage: 'Air suspension - ECAS valve 1 point control',
    description: ''
  },
  airSuspensionEcas2: {
    id: 'short.airSuspensionEcas2',
    defaultMessage: 'Air suspension - ECAS valve 2 point control',
    description: ''
  },
  airSuspensionEtasc1: {
    id: 'short.airSuspensionEtasc1',
    defaultMessage: 'Air suspension - eTASC valve 1 point control',
    description: ''
  },
  airSuspensionEtasc2: {
    id: 'short.airSuspensionEtasc2',
    defaultMessage: 'Air suspension - eTASC valve 2 point control',
    description: ''
  },
  airSuspensionEcas2FrontRear: {
    id: 'short.airSuspensionEcas2FrontRear',
    defaultMessage: 'Air suspension - ECAS valve 2 point control front rear valves',
    description: ''
  },
  airSuspensionLevelingValve: {
    id: 'short.airSuspensionLevelingValve',
    defaultMessage: 'Air suspension - Leveling valve',
    description: ''
  },
  hydraulicSuspension: {
    id: 'short.hydraulicSuspension',
    defaultMessage: 'Hydraulic suspension',
    description: ''
  },
  mechanicalSuspensionWithHeightSensor: {
    id: 'short.mechanicalSuspensionWithHeightSensor',
    defaultMessage: 'Mechanical suspension with heigh sensor',
    description: ''
  }
});

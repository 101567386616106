import { defineMessages } from 'react-intl';

// Section of text long, or specific, enough to not be reused elsewhere. Often the text describing a phrase above so will share same end id
export const description = defineMessages({
  accessRevoked: {
    id: 'description.accessRevoked',
    defaultMessage: 'Your link is invalid or has expired.',
    description: ''
  },
  deleteNotificationsVal: {
    id: 'description.deleteNotificationsVal',
    defaultMessage: 'Please confirm that you want to delete all your {value} notifications.',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  durationVal: {
    id: 'description.durationVal',
    defaultMessage:
      'Score calculated as a function of the average number of consecutive days within a {critical} and a {warning} state (sum of the two scores capped by 10).',
    description:
      '{critical} and {warning} are variables and should not be translated. {critical} and {warning} be the strings critical and warning in bold.'
  },
  ebsAmberNotification: {
    id: 'description.ebsAmberNotification',
    defaultMessage: "Get notified when a device sends 'EBS Amber light' event",
    description: 'EBS Electronic Braking System. Amber light as in amber coloured bulb that lights up'
  },
  absNotification: {
    id: 'description.absNotification',
    defaultMessage: "Get notified when a device sends 'ABS' event",
    description: 'ABS - automatic braking system'
  },
  rssNotification: {
    id: 'description.rssNotification',
    defaultMessage: "Get notified when a device sends 'RSS' event",
    description: 'RSS - Roll Stability Support'
  },
  ebsRedNotification: {
    id: 'description.ebsRedNotification',
    defaultMessage: "Get notified when a device sends 'EBS Red light' event",
    description: 'EBS Electronic Braking System. Red light as in red coloured bulb that lights up'
  },
  fleetHealthEvolution: {
    id: 'description.fleetHealthEvolution',
    defaultMessage: 'A week-to-week evolution of the health of your fleet',
    description: ''
  },
  harshBrakeNotification: {
    id: 'description.harshBrakeNotification',
    defaultMessage: "Get notified when a device sends 'Harsh brake' event",
    description: ''
  },
  isoNotification: {
    id: 'description.isoNotification',
    defaultMessage: "Get notified when a device sends 'Driving without ISO cable' event",
    description: 'ISO is type of cable do not translate'
  },
  geofenceNotification: {
    id: 'description.geofenceNotification',
    defaultMessage: 'Get notified when a trailer enters or leaves a geofence',
    description: 'Label of a switch for receiving a notification. A geofence is an area on the map'
  },
  howIsScoreComputed: {
    id: 'description.howIsScoreComputed',
    defaultMessage:
      'The health score is based on the combination of 3 subscores that encompasses 3 different concepts: Recency, Duration and Frequency. It is defined as the average of these 3 scores. The higher the score, the worse the trailer health. In case where the amount of observed period of activity is less then a month, then the health score is computed in a similar way but substitute days by 100km segments of activity (a red badge is displayed on the score when this substitution is performed)',
    description: 'Tooltip explanation. km abbreviation of unit of distance kilometers'
  },
  howIsScoreComputedImperial: {
    id: 'description.howIsScoreComputedImperial',
    defaultMessage:
      'The health score is based on the combination of 3 subscores that encompasses 3 different concepts: Recency, Duration and Frequency. It is defined as the average of these 3 scores. The higher the score, the worse the trailer health. In case where the amount of observed period of activity is less then a month, then the health score is computed in a similar way but substitute days by 62mi segments of activity (a red badge is displayed on the score when this substitution is performed)',
    description: 'Tooltip explanation'
  },
  lastYearSummary: {
    id: 'description.lastYearSummary',
    defaultMessage:
      'Timeline showing the worst event on a daily basis over the course of a year. Each dot represents a day and its color corresponds to the severity of the worst event that occurred during the very same day:',
    description: ''
  },
  last10kKmSummary: {
    id: 'description.last10kKmSummary',
    defaultMessage:
      'Timeline showing the worst event on a 100 km basis over the course of a 10,000 km. Each dot represent a period of 100 km and its color corresponds to the severity of the worst event that occurred during the very same day:',
    description: 'km abbreviation of unit of distance kilometers'
  },
  powerOnNotification: {
    id: 'description.powerOnNotification',
    defaultMessage: "Get notified when a device sends a 'Power On' event",
    description: ''
  },
  recentSummary: {
    id: 'description.recentSummary',
    defaultMessage:
      'Timeline showing the worst event on a time or mileage basis. Each dot represents a day / 100-km period and its color corresponds to the severity of the worst event that occurred during this period:',
    description: 'km abbreviation of unit of distance kilometers'
  },
  recentSummaryMile: {
    id: 'description.recentSummaryMile',
    defaultMessage:
      'Timeline showing the worst event on a time or mileage basis. Each dot represents a day / 62-mi period and its color corresponds to the severity of the worst event that occurred during this period:',
    description: 'mi abbreviation of unit of distance mile'
  },
  startStopNotification: {
    id: 'description.startStopNotification',
    defaultMessage: "Get notified when a device sends a 'Start / Stop' event",
    description: ''
  },
  emailTemplateDescription: {
    id: 'emailTemplateDescription',
    defaultMessage:
      'Here is a preview of the email that will be sent to the registered user email that you provided. The placeholders will be replaced by the proper values when the email is written.',
    description: ''
  },
  tpmsNotification: {
    id: 'description.tpmsNotification',
    defaultMessage: "Get notified when a device sends a tire pressure ('TPMS') event",
    description: 'TPMS - tire pressure monitoring system'
  },
  harshBrakeEvent: {
    id: 'description.harshBrakeEvent',
    defaultMessage: 'A deceleration of 10km/h in 1 second was detected.',
    description: 'km/h abbreviation of unit of speed kilometers per hour'
  },
  ebsAmber: {
    id: 'description.ebsAmber',
    defaultMessage: 'An amber light from EBS was turned on.',
    description: 'EBS Electronic Braking System. Amber light as in amber coloured bulb that lights up'
  },
  ebsRed: {
    id: 'description.ebsRed',
    defaultMessage: 'A red light from EBS was turned on.',
    description: 'EBS Electronic Braking System. Red light as in red coloured bulb that lights up'
  },
  tpms: {
    id: 'description.tpms',
    defaultMessage: 'Pressure warning reported by installed TPMS.',
    description: 'TPMS - tire pressure monitoring system'
  },
  rss: {
    id: 'description.rss',
    defaultMessage: 'A Roll Stability Support warning (with deceleration) was detected.',
    description: 'Roll Stability Support is a name do not translate'
  },
  abs: {
    id: 'description.abs',
    defaultMessage: 'ABS brake action detected.',
    description: 'ABS - automating braking system'
  },
  withoutISOCable: {
    id: 'description.withoutISOCable',
    defaultMessage: 'Driving with ISO cable disconnected detected. Only applicable for a device with battery.',
    description: 'ISO is type of cable do not translate'
  },
  geofence: {
    id: 'description.geofence',
    defaultMessage: 'Trailer enters or leave a geofences.',
    description: 'A geofence is an area on the map'
  },
  functionMonitorInputSignal: {
    id: 'description.functionMonitorInputSignal',
    defaultMessage: 'This function monitors the input signal switch-on duration.',
    description: ''
  },
  hrsTillNextService: {
    id: 'description.hrsTillNextService',
    defaultMessage: 'Number of hours left until the next service are operation hours not hours in a calendar day.',
    description: ''
  },
  wabcoEBSOnly: {
    id: 'description.wabcoEBSOnly',
    defaultMessage: 'Data can only be displayed for WABCO EBS.',
    description: 'EBS Electronic Braking System.'
  },
  seeDocForMoreInfo: {
    id: 'description.seeDocForMoreInfo',
    defaultMessage: 'Refer to T-EBS Diagnostic Software documentation for more information.',
    description: 'T-EBS is a name do not translate'
  },
  idCardUnavailable: {
    id: 'description.idCardUnavailable',
    defaultMessage: 'Trailer ID cards are not available for trailers with third-party EBS brands',
    description: 'EBS Electronic Braking System.'
  },
  recencyVal: {
    id: 'description.recencyVal',
    defaultMessage:
      'Score calculated as a function of the number of days or number of hundreds of kilometers since the last {critical} or the last {warning} event (max of the two scores).',
    description:
      '{critical} and {warning} are variables and should not be translated. {critical} and {warning} are passed seperately as these will be bold text'
  },
  recencyValImperial: {
    id: 'description.recencyValImperial',
    defaultMessage:
      'Score calculated as a function of the number of days or number of segments of 62 miles since the last {critical} or the last {warning} event (max of the two scores).',
    description:
      '{critical} and {warning} are variables and should not be translated. {critical} and {warning} are passed seperately as these will be bold text'
  },
  durationValimperial: {
    id: 'description.durationValimperial',
    defaultMessage:
      'Score calculated as a function of the average number of consecutive days or segments of 62 miles within a {critical} and a {warning} state (sum of the two scores capped by 10).',
    description:
      '{critical} and {warning} are variables and should not be translated. {critical} and {warning} are passed seperately as these will be bold text'
  },
  durationValMetric: {
    id: 'description.durationValMetric',
    defaultMessage:
      'Score calculated as a function of the average number of consecutive days or hundreds of kilometers within a {critical} and a {warning} state (sum of the two scores capped by 10).',
    description:
      '{critical} and {warning} are variables and should not be translated. {critical} and {warning} are passed seperately as these will be bold text'
  },
  frequencyVal: {
    id: 'description.frequencyVal',
    defaultMessage:
      'Score calculated as a function of the average number of days or number of hundreds of kilometers between two {critical} or two {warning} events (sum of the two scores capped by 10).',
    description:
      '{critical} and {warning} are variables and should not be translated. {critical} and {warning} are passed seperately as these will be bold text'
  },
  frequencyValImperial: {
    id: 'description.frequencyValImperial',
    defaultMessage:
      'Score calculated as a function of the average number of days or number of segments of 62 miles between two {critical} or two {warning} events (sum of the two scores capped by 10).',
    description:
      '{critical} and {warning} are variables and should not be translated. {critical} and {warning} are passed seperately as these will be bold text'
  },
  unqualifiedMeaning: {
    id: 'description.unqualifiedMeaning',
    defaultMessage:
      "After 30 day, an 'unqualified'  Braking Performance Value is produced. However,  more braking events are required to enable a valid Braking Performance Value to be produced.",
    description: 'Tooltip explanation'
  },
  lowPerformanceMeaning: {
    id: 'description.lowPerformanceMeaning',
    defaultMessage: 'The Braking Performance Value is below 45%',
    description: ''
  },
  actionRequiredMeaning: {
    id: 'short.actionRequiredMeaning',
    defaultMessage: 'The Braking Performance Value is below 45% at 7.5B coupling head pressure',
    description: ''
  },
  subscriptionSettings: {
    id: 'description.subscriptionSettings',
    defaultMessage: 'Get notified via email or in-app notifications',
    description: ''
  },
  monthlyMileageReport: {
    id: 'description.monthlyMileageReport',
    defaultMessage: 'Monthly mileage per trailer',
    description: ''
  },
  pageError: {
    id: 'description.pageError',
    defaultMessage: `There is a technical problem with the application that has prevented this page from loading. Try reloading
      this page or loading another page. If that doesn't work try again later.`,
    description: 'Error Page generic error explanation'
  }
});
